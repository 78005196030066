import { roundUpto } from "~/utils/constants/numbers";
import useBaseUtils from "~/functions/useBaseUtils";
export default {
  __name: 'ResultsMessage',
  props: {
    epsCagr: {
      type: Object,
      "default": function _default() {}
    },
    priceOverEarningsMultiple: {
      type: Object,
      "default": function _default() {}
    },
    dividendYield: {
      type: Object,
      "default": function _default() {}
    }
  },
  setup: function setup(__props) {
    var props = __props;
    var _useBaseUtils = useBaseUtils(),
      I18nFn = _useBaseUtils.I18nFn;
    var formattedEpsCagr = computed(function () {
      var _props$epsCagr;
      var value = (props === null || props === void 0 || (_props$epsCagr = props.epsCagr) === null || _props$epsCagr === void 0 ? void 0 : _props$epsCagr.v) * 100;
      var rounded = roundUpto(value, 2);
      return "".concat(rounded, "%");
    });
    var formattedPEMultiple = computed(function () {
      var _props$priceOverEarni;
      var value = (props === null || props === void 0 || (_props$priceOverEarni = props.priceOverEarningsMultiple) === null || _props$priceOverEarni === void 0 ? void 0 : _props$priceOverEarni.v) * 100;
      var rounded = roundUpto(value, 2);
      return "".concat(rounded, "%");
    });
    var formattedDividendYield = computed(function () {
      var _props$dividendYield, _roundUpto;
      var value = (props === null || props === void 0 || (_props$dividendYield = props.dividendYield) === null || _props$dividendYield === void 0 ? void 0 : _props$dividendYield.v) * 100;
      var rounded = (_roundUpto = roundUpto(value, 2)) !== null && _roundUpto !== void 0 ? _roundUpto : 0;
      return "".concat(rounded, "%");
    });
    return {
      __sfc: true,
      props: props,
      I18nFn: I18nFn,
      formattedEpsCagr: formattedEpsCagr,
      formattedPEMultiple: formattedPEMultiple,
      formattedDividendYield: formattedDividendYield
    };
  }
};